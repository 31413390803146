@font-face {
  font-family: CustomFont;
  src: url("./fonts/Gotham_Book_Regular/Gotham_Book_Regular.otf");
  /* src: url('./fonts/Ethos_Typefaces/ETHOS-REGULAR.OTF'); */
}

body {
  margin: 0;
  padding: 0;
  font-family: "CustomFont", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

svg {
  text-align: center;
  margin: auto;
  width: 100px;
  height: 100px;
}

#icon-qr-code .scan-bar {
  animation: scanning-bar linear 2s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: scanning-bar linear 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
}

@keyframes scanning-bar {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  24% {
    -webkit-transform: translate(0px, 20px);
    transform: translate(0px, 20px);
  }
  33% {
    -webkit-transform: translate(0px, 20px);
    transform: translate(0px, 20px);
  }
  50% {
    -webkit-transform: translate(0px, 24px);
    transform: translate(0px, 24px);
  }
  100% {
    -webkit-transform: translate(0px, 24px);
    transform: translate(0px, 24px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@-webkit-keyframes scanning-bar {
  0% {
    -webkit-transform: translate(0px, 0px);
  }
  24% {
    -webkit-transform: translate(0px, 20px);
  }
  33% {
    -webkit-transform: translate(0px, 20px);
  }
  50% {
    -webkit-transform: translate(0px, 24px);
  }
  100% {
    -webkit-transform: translate(0px, 24px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
  }
}
